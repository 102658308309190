.modal-container {
  align-items: center;
  background: rgba(2, 103, 170, 0.30);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.modal {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  max-width: calc( 90vw - ( 2 * 32px ) );
  padding: 32px;
  width: calc( 1156px - ( 2 * 32px ) );

  &-title {
    color: #0267AA;
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 39px;
    text-align: center;
  }

  &-body {
    margin-bottom: 28px;
  }

  &-footer {
    display: flex;
    justify-content: center;

    button:first-child {
      margin-right: 19px;
    }
  }
}