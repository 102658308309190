@font-face {

    font-family: "DK Raleway";
    font-style: normal;
    font-weight: 600;
    src: url( "./dk-raleway/semi-bold.otf" ) format( "opentype" );

}

@font-face {

    font-family: "DK Raleway";
    font-style: normal;
    font-weight: 700;
    src: url( "./dk-raleway/bold.otf" ) format( "opentype" );

}

@font-face {

    font-family: "DK Raleway";
    font-style: normal;
    font-weight: 900;
    src: url( "./dk-raleway/black.otf" ) format( "opentype" );

}