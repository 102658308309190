main.profile {

    display: flex;
    flex-direction: column;
    font-family: 'DK Raleway', sans-serif;
    justify-content: space-between;
    min-height: 100vh;

    .space {
        min-height: 51px;
    }

    .container {

        margin: 0 auto;
        width: 1160px;

        @media (max-width: 576px) {
            padding-left: 15px;
            padding-right: 15px;
            width: calc(100% - 30px);
        }

    }

    .logo {

        height: 47px;
        margin-bottom: 40px;

    }

    h2 {

        color: #C30870;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin-bottom: 30px;

    }

    .info {

        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 45px;
        max-width: 437px;

    }

    button {
        align-items: center;
        border: none;
        border-radius: 21.5px;
        background: #0267AA;
        color: #FFF;
        cursor: pointer;
        font-family: DK Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        display: flex;
        height: 35px;
        justify-content: center;
        line-height: normal;
        text-align: center;
        width: 186px;

        &.warning {
            background-color: rgba(195, 8, 112, 0.20);
            border: 1px solid #C30870;
            border-radius: 21.5px;
            color: #C30870;
        }
    }

    header {

        background-color: #0267AA;
        height: 37.5px;
        padding: 11.25px 0;


        img {

            height: 37.5px;

        }

        .container {
            align-items: center;
            display: flex;
            justify-content: space-between;

            .user {
                align-items: center;
                display: flex;

                span {
                    color: #FFF;
                    text-align: right;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: normal;

                    @media (max-width: 576px) {
                        display: none;
                    }
                }

                img {
                    cursor: pointer;
                    height: 24px;
                    margin-left: 33px;
                    width: 24px;
                }
            }
        }

    }

    footer {

        background-color: #0267AA;
        height: 60px;

        .container {

            align-items: center;
            display: flex;
            height: 100%;
            justify-content: space-between;

            a {

                color: white;
                font-size: 12px;
                font-weight: 700;
                line-height: 14px;
                text-decoration: none;

            }

        }

    }

    .modal-body {
        color: #59595B;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
    }

}