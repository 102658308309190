main.login {

    display: flex;
    flex-direction: column;
    font-family: 'DK Raleway', sans-serif;
    justify-content: space-between;
    min-height: 100vh;

    .space {
        min-height: 51px;
    }

    .container {

        margin: 0 auto;
        width: 1160px;

        &--center {

            align-items: center;
            display: flex;
            flex-direction: column;

        }

        @media (max-width: 576px) {
            padding-left: 15px;
            padding-right: 15px;
            width: calc(100% - 30px);
        }

    }

    .logo {

        height: 47px;
        margin-bottom: 40px;

    }

    h2 {

        color: #0267AA;
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        margin: 0 0 15px 0;
        text-align: center;

    }

    .info {

        color: #0267AA;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-align: center;
        width: 399px;

        @media (max-width: 576px) {
            padding-left: 15px;
            padding-right: 15px;
            max-width: calc(100% - 30px);
        }

    }

    form {

        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        width: 399px;

        input[ type="email"],
        input[ type="password"] {

            border: none;
            border-radius: 8px;
            background-color: rgba(2, 103, 170, 0.1);
            font-family: 'DK Raleway', sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-bottom: 10px;
            padding: 18px 15px;
            width: calc(100% - (2 * 15px));

            &::placeholder {

                color: #59595B;

            }

        }

        input[ type="submit"] {

            background-color: #0267AA;
            border: none;
            border-radius: 17.5px;
            color: white;
            cursor: pointer;
            font-family: 'DK Raleway', sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            padding: 8px;
            width: calc(186px - (2 * 8px));

        }

        .warning-message {

            align-self: flex-start;
            color: firebrick;
            font-size: 12px;
            font-weight: 600;
            line-height: 12px;
            margin-bottom: 10px;

        }

        @media (max-width: 576px) {
            max-width: 100%;
        }

    }

    .social-login {
        border-top: 1px solid #E3E3E3;
        display: flex;
        margin-top: 146px;
        padding-top: 30px;
        width: 1168px;

        .social-button {
            align-items: center;
            background: #FFFFFF;
            border: 1px solid #0267AA;
            border-radius: 24px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
            color: #0267AA;
            cursor: pointer;
            display: flex;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            height: 48px;
            line-height: normal;
            margin-right: 45px;
            justify-content: center;
            position: relative;
            text-align: center;
            width: 359px;

            img {
                height: 20px;
                left: 16px;
                object-fit: contain;
                object-position: center center;
                position: absolute;
                top: 14px;
                width: 20px;
            }

            &:last-child {
                margin-right: 0;
            }

            @media (max-width: 576px) {
                margin-right: 0;
                margin-bottom: 10px;
                width: 100%;
            }
        }

        @media (max-width: 576px) {
            flex-wrap: wrap;
            padding-left: 15px;
            padding-right: 15px;
            max-width: calc(100% - 30px);
        }
    }


    header {

        background-color: #0267AA;
        height: 37.5px;
        padding: 11.25px 0;


        img {

            height: 37.5px;

        }

    }

    footer {

        background-color: #0267AA;
        height: 60px;

        .container {

            align-items: center;
            display: flex;
            height: 100%;
            justify-content: space-between;

            a {

                color: white;
                font-size: 12px;
                font-weight: 700;
                line-height: 14px;
                text-decoration: none;

            }

        }

    }

}