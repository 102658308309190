body {

    margin: 0;

}

main.home {

    font-family: 'DK Raleway', sans-serif;
    padding-bottom: 73px;

    .background {

        display: none;
        height: 100vh;
        left: 0;
        object-fit: cover;
        object-position: center center;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: -1;

        &--desktop {

            @media ( min-width: 576px ) {

                display: block;

            }

        }

        &--mobile {

            @media ( max-width: 575px ) {

                display: block;

            }

        }

    }

    .container {

        display: grid;
        grid-column-gap: 32px;
        grid-template-columns: repeat( 12, calc( 848px / 12 ) );
        margin-left: auto;
        margin-right: auto;
        width: 1200px;

        @media ( max-width: 575px ) {

            grid-column-gap: 12px;
            grid-template-columns: repeat( 6, calc( 310px / 6 ) );
            width: 370px;

        }

    }

    .logo {

        grid-column-start: 10;
        margin-top: 115px;

        @media ( max-width: 575px ) {

            grid-column-start: 1;
            grid-column-end: 7;
            margin-bottom: 38px;
            margin-top: 43px;
            text-align: center;

            img {

                width: 268px;

            }

        }

    }

    .content {

        color: #FFFFFF;
        grid-column-start: 1;
        grid-column-end: 7;
        margin-bottom: 180px;
        text-align: center;

        h1 {

            font-size: 38px;
            font-weight: 700;
            letter-spacing: -0.87px;
            line-height: 44.61px;

            @media ( max-width: 575px ) {

                font-size: 27px;
                font-weight: 700;
                letter-spacing: -0.62px;
                line-height: 30px;
                max-width: 358px;

            }

        }

        .description {

            font-size: 12px;
            font-weight: 600;
            letter-spacing: -0.38px;
            line-height: 14.09px;
            margin: 19px auto 52px auto;
            max-width: 488px;

            @media ( max-width: 575px ) {

                margin: 25px auto 25px auto;
                max-width: 358px;

            }

        }

        .links {

            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 52px;

            a {

                text-decoration: none;

                &:first-child {

                    margin-right: 32px;

                    @media ( max-width: 575px ) {

                        margin-bottom: 12px;
                        margin-right: 0;

                    }

                }

                img {

                    width: 180px;

                    @media ( max-width: 575px ) {

                        width: 173px;

                    }

                }

            }

            @media ( max-width: 575px ) {

                flex-direction: column;
                margin-bottom: 25px;

            }

        }

        .info {

            font-size: 12px;
            font-weight: 600;
            letter-spacing: -0.38px;
            line-height: 14.09px;
            margin-bottom: 52px;
            max-width: 532px;

            a {

                color: inherit;

            }

            @media ( max-width: 575px ) {

                margin-bottom: 30px;

            }

        }

        .cta {

            font-size: 18px;
            font-weight: 700;
            letter-spacing: -0.41px;
            line-height: 20px;

        }

        @media ( max-width: 575px ) {

            margin-bottom: 25px;

        }

    }

    footer {

        display: flex;
        grid-column-start: 1;
        grid-column-end: 13;

        div {

            text-align: center;
            width: calc( 100% / 3 );

            a {

                color: white;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: -0.38px;
                line-height: 14.09px;

                @media ( max-width: 575px ) {

                    display: inline-flex;
                    margin-bottom: 15px;

                }

            }

            @media ( max-width: 575px ) {

                width: 100%;

            }

        }

        @media ( max-width: 575px ) {

            flex-direction: column;
            grid-column-start: 1;
            grid-column-end: 7;

        }

    }

    @media ( max-width: 575px ) {

        padding-bottom: 101px;

    }

}
